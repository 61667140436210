<template>
    <div>
        <topBarY />
        <div class="bg">
            <img src="../../assets/common/pic/bg.jpg" alt="">
        </div>
        <div class="switchBar">
            <div class="tab productLiOne" :class="{ active: switchIndex == 1 }" @click="switchClick(1)">工业覆盖传输产品系列</div>
            <div class="tab productLiTwo" :class="{ active: switchIndex == 2 }" @click="switchClick(2)">无线车载传输产品系列</div>
            <div class="tab productLiThree" :class="{ active: switchIndex == 3 }" @click="switchClick(3)">相关无线覆盖传输产品规格选型</div>
            <div class="tab productLiFour" :class="{ active: switchIndex == 4 }" @click="switchClick(4)">无线系统管理平台</div>
            <div class="tab productLiSix" :class="{ active: switchIndex == 5 }" @click="switchClick(5)">无线选配附件系列</div>
            <div class="tab productLiSeven" :class="{ active: switchIndex == 5 }" @click="switchClick(6)">智能无线保管柜系列</div>
        </div>
        <div class="line"></div>
        <div class="switchContiner">
            <div class="productContinerOne productContiner" v-if="switchIndex == 1">
                <div class="productOne"> 
                    <div class="productBox" @click="productOneOneClick()">
                        <img src="../../assets/support/wirelessFastSwitchingBaseStation.png" alt="" class="pic one">
                        <span class="txt">无线快速切换基站</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productOneTwoClick()">
                        <img src="../../assets/support/explosionProofAP.png" alt="" class="pic two">
                        <span class="txt">防爆型无线快速切换基站</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productOneThreeClick()">
                        <img src="../../assets/support/wirelessBaseStation.png" alt="" class="pic three">
                        <span class="txt">吸顶式室内无线接入点</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productOneFourClick()">
                        <img src="../../assets/support/wirelessTransmissionBridge.png" alt="" class="pic four">
                        <span class="txt">高带宽无线传输网桥</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                </div>
            </div>
            <div class="productContinerTwo productContiner" v-if="switchIndex == 2">
                <div class="productTwo">
                    <div class="productBox" @click="productTwoOneClick()">
                        <img src="../../assets/support/vehicleWirelessUnit.png" alt="" class="pic one">
                        <span class="txt">车载无线单元</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productTwoTwoClick()">
                        <img src="../../assets/support/routingGateway.png" alt="" class="pic two">
                        <span class="txt">4/5G路由网关</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productTwoThreeClick()">
                        <img src="../../assets/support/communicationProcessor.png" alt="" class="pic three">
                        <span class="txt">工业级32位通信处理器</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productTwoFourClick()">
                        <img src="../../assets/support/wirelessClient.png" alt="" class="pic four">
                        <span class="txt">双频无线客户端</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                </div>
            </div>
            <div class="productContinerThree productContiner" v-if="switchIndex == 3">
                <div class="productThree">
                    <div class="productBox" @click="productThreeOneClick()">
                        <img src="../../assets/support/wirelessAccessPointController2.png" alt="" class="pic one">
                        <span class="txt">无线接入控制器</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productThreeTwoClick()">
                        <img src="../../assets/support/gigabitEthernetSwitch.png" alt="" class="pic two">
                        <span class="txt">工业级千兆以太交换机</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productThreeThreeClick()">
                        <img src="../../assets/support/intelligentCloudGateway.png" alt="" class="pic three">
                        <span class="txt">智能云网关</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                </div>
            </div>
            <div class="productContinerFour productContiner" v-if="switchIndex == 4">
                <div class="productFour">
                    <div class="productBox" @click="productFourOneClick()">
                        <img src="../../assets/support/managementPlatform.png" alt="" class="pic one">
                        <span class="txt">云智能维护管理平台</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productFourTwoClick()">
                        <img src="../../assets/support/middleware.jpg" alt="" class="pic two">
                        <span class="txt">智能接入中间件平台</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <!-- <div class="productBox" @click="productFourThreeClick()">
                        <img src="../../assets/support/storageCabinetLogo.png" alt="" class="pic three">
                        <span class="txt">智能无线保管柜平台</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productFourFourClick()">
                        <img src="../../assets/support/WNMScloud.png" alt="" class="pic four">
                        <span class="txt">WNMS云</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productFourFiveClick()">
                        <img src="../../assets/support/WNMScloud.png" alt="" class="pic four">
                        <span class="txt">数字孪生</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div> -->
                </div>
            </div>
            <div class="productContinerFive productContiner" v-if="switchIndex == 5">
                <div class="productFive">
                    <div class="productBox" @click="productFiveOneClick()">
                        <img src="../../assets/support/POE.png" alt="" class="pic one">
                        <span class="txt">POE供电模块</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <!-- <div class="productBox" @click="productFiveTwoClick()">
                        <img src="../../assets/support/omnidirectionalAntenna.png" alt="" class="pic two">
                        <span class="txt">双频全向天线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productFiveThreeClick()">
                        <img src="../../assets/support/directionalAntenna.png" alt="" class="pic three">
                        <span class="txt">双频定向天线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productFiveFourClick()">
                        <img src="../../assets/support/PCBdirectionalAntenna.png" alt="" class="pic four">
                        <span class="txt">PCB定向天线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div> -->
                    <div class="productBox" @click="productFiveFiveClick()">
                        <img src="../../assets/support/singleFrequencyMushroomAntenna.png" alt="" class="pic five">
                        <span class="txt">单频蘑菇天线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productFiveSixClick()">
                        <img src="../../assets/support/doubleFrequencyMushroomAntenna.png" alt="" class="pic six">
                        <span class="txt">双频蘑菇天线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <!-- <div class="productBox" @click="productFiveSevenClick()">
                        <img src="../../assets/support/feeder.png" alt="" class="pic seven">
                        <span class="txt">工业级馈线</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox last" @click="productFiveEightClick()">
                        <img src="../../assets/support/powerDivider.png" alt="" class="pic eight">
                        <span class="txt">超宽频功分器</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productFiveNineClick()">
                        <img src="../../assets/support/coupler.png" alt="" class="pic nine">
                        <span class="txt">超宽频耦合器</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productFiveTenClick()">
                        <img src="../../assets/support/optimizationUnit.png" alt="" class="pic ten">
                        <span class="txt">馈电智能信号传输优化单元</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div> -->
                </div>
            </div>
            <div class="productContinerSix productContiner" v-if="switchIndex == 6">
                <div class="productSix">
                    <div class="productBox" @click="productSixOneClick()">
                        <img src="../../assets/support/cabinet.png" alt="" class="pic one">
                        <span class="txt">智能无线保管柜</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                    <div class="productBox" @click="productSixTwoClick()">
                        <img src="../../assets/support/equipment.png" alt="" class="pic two">
                        <span class="txt">保管柜模组设备</span>
                        <img src="../../assets/support/next.png" alt="" class="next">
                    </div>
                </div>
            </div>
        </div>
        <baseBar />
    </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
    name: 'support',
    components: {
        topBarY,
        baseBar
    },
    data() {
        return {
            switchIndex: 1,
            proIndex: 1
        }
    },
    methods: {
        switchClick(index) {
            this.switchIndex = index
        },
        proClick(index) {
            this.proIndex = index
        },
        engineeringClick() {
            this.$router.push('engineering')
        },
        guideClick() {
            this.$router.push('guide')
        },
        loginClick() {
            this.$router.push('login')
        },
        // 第一类产品点击
        productOneOneClick() {
            this.$router.push('productOneOne')
        },
        productOneTwoClick() {
            this.$router.push('productOneTwo')
        },
        productOneThreeClick() {
            this.$router.push('productOneThree')
        },
        productOneFourClick() {
            this.$router.push('productOneFour')
        },
        // 第二类产品点击
        productTwoOneClick() {
            this.$router.push('productTwoOne')
        },
        productTwoTwoClick() {
            this.$router.push('productTwoTwo')
        },
        productTwoThreeClick() {
            this.$router.push('productTwoThree')
        },
        productTwoFourClick() {
            this.$router.push('productTwoFour')
        },
        // 第三类产品点击
        productThreeOneClick() {
            this.$router.push('productThreeOne')
        },
        productThreeTwoClick() {
            this.$router.push('productThreeTwo')
        },
        productThreeThreeClick() {
            this.$router.push('productThreeThree')
        },
        productThreeFourClick() {
            this.$router.push('productThreeFour')
        },
        // 第四类产品点击
        productFourOneClick() {
            this.$router.push('productFourOne')
        },
        productFourTwoClick() {
            this.$router.push('productFourTwo')
        },
        productFourThreeClick() {
            this.$router.push('productFourThree')
        },
        productFourFourClick() {
            this.$router.push('productFourFour')
        },
        productFourFiveClick() {
            this.$router.push('productFourFive')
        },
        // 第五类产品点击
        productFiveOneClick() {
            this.$router.push('productFiveOne')
        },
        productFiveTwoClick() {
            this.$router.push('productFiveTwo')
        },
        productFiveThreeClick() {
            this.$router.push('productFiveThree')
        },
        productFiveFourClick() {
            this.$router.push('productFiveFour')
        },
        productFiveFiveClick() {
            this.$router.push('productFiveFive')
        },
        productFiveSixClick() {
            this.$router.push('productFiveSix')
        },
        productFiveSevenClick() {
            this.$router.push('productFiveSeven')
        },
        productFiveEightClick() {
            this.$router.push('productFiveEight')
        },
        productFiveNineClick() {
            this.$router.push('productFiveNine')
        },
        productFiveTenClick() {
            this.$router.push('productFiveTen')
        },
        // 第六类产品点击
        productSixOneClick() {
            this.$router.push('productSixOne')
        },
        productSixTwoClick() {
            this.$router.push('productSixTwo')
        },
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    width: 1920px;
    height: 480px;
    overflow: hidden;
}

.bg img {
    position: absolute;
    top: -100px;
    width: 1920px;
}

.switchBar {
    padding-left: 25px;
    height: 70px;
    background-color: #fff;
    text-align: center;
}

.switchBar .tab {
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 190px;
    height: 40px;
    background-color: #c3a35d;
    color: #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-size: 14px;
    line-height: 40px;
}
.switchBar .productLiThree {
    width: 240px;
}

.switchBar .productLiFour {
    width: 160px;
}

.switchBar .productLiSix {
    width: 160px;
}

.switchBar .productLiSeven {
    width: 160px;
    margin-right: 0;
}
.line {
    margin: 0 auto;
    width: 1400px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner {
    width: 1920px;
    padding: 20px;
    background-color: #f7f7f7;
}

/* 产品支持 */
.switchContiner .productContiner {
    margin: 0 auto;
    padding-top: 20px;
    width: 1400px;
    height: 560px;
    border-radius: 5px;
    background-color: #fff;
}

.switchContiner .productContinerOne {
    height: 440px;
}

.switchContiner .productContinerTwo {
    height: 440px;
}

.switchContiner .productContinerThree {
    height: 440px;
}

.switchContiner .productContinerFour {
    height: 440px;
}

.switchContiner .productContinerFive {
    height: 440px;
}

.switchContiner .productContinerSix {
    height: 440px;
}

.switchContiner .productContiner .line {
    margin: 0 auto;
    margin-top: 20px;
    width: 1034px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner .productContiner .pro {
    text-align: center;
}

.switchContiner .productContiner .pro .engli {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    width: 240px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 5px;
    background-color: #c3a35d;
    color: #f5f5f5;
}

.switchContiner .productContiner .pro .one {
    width: 180px;
}

.switchContiner .productContiner .pro .two {
    width: 180px;
}

.switchContiner .productContiner .pro .four {
    width: 160px;
}

.switchContiner .productContiner .pro .five {
    width: 160px;
}

.switchContiner .productContiner .pro li:last-child {
    margin: 0;
}

/* A类产品 */
.switchContiner .productContiner .productOne {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productOne .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productOne .productBox .pic {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productOne .productBox .one {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productOne .productBox .two {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
}

.switchContiner .productContiner .productOne .productBox .three {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 80px;
}

.switchContiner .productContiner .productOne .productBox .four {
    position: absolute;
    top: 25px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productOne .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productOne .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productOne .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productOne .last {
    margin-right: 0;
}

/* B类产品 */
.switchContiner .productContiner .productTwo {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productTwo .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productTwo .productBox .pic {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productTwo .productBox .one {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productTwo .productBox .two {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 110px;
}

.switchContiner .productContiner .productTwo .productBox .three {
    position: absolute;
    top: 25px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productTwo .productBox .four {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 100px;
}

.switchContiner .productContiner .productTwo .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productTwo .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productTwo .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productTwo .last {
    margin-right: 0;
}

/* C类产品 */
.switchContiner .productContiner .productThree {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productThree .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productThree .productBox .pic {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productThree .productBox .one {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productThree .productBox .two {
    position: absolute;
    top: 20px;
    left: 35px;
    width: 40px;
}

.switchContiner .productContiner .productThree .productBox .three {
    position: absolute;
    top: 42px;
    left: 35px;
    width: 100px;
}

.switchContiner .productContiner .productThree .productBox .four {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 100px;
}

.switchContiner .productContiner .productThree .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productThree .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productThree .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productThree .last {
    margin-right: 0;
}

/* D类产品 */
.switchContiner .productContiner .productFour {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productFour .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productFour .productBox .pic {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productFour .productBox .one {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 140px;
}

.switchContiner .productContiner .productFour .productBox .two {
    position: absolute;
    top: 30px;
    left: 20px;
    width: 140px;
}

.switchContiner .productContiner .productFour .productBox .three {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 140px;
}

.switchContiner .productContiner .productFour .productBox .four {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 140px;
}

.switchContiner .productContiner .productFour .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productFour .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productFour .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productFour .last {
    margin-right: 0;
}

/* E类产品 */
.switchContiner .productContiner .productFive {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productFive .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productFive .productBox .pic {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productFive .productBox .one {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 80px;
}

.switchContiner .productContiner .productFive .productBox .two {
    position: absolute;
    top: 20px;
    left: 35px;
    width: 8px;
}

.switchContiner .productContiner .productFive .productBox .three {
    position: absolute;
    top: 30px;
    left: 35px;
    width: 50px;
}

.switchContiner .productContiner .productFive .productBox .four {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 100px;
}

.switchContiner .productContiner .productFive .productBox .five {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 80px;
}

.switchContiner .productContiner .productFive .productBox .six {
    position: absolute;
    top: 25px;
    left: 35px;
    width: 50px;
}

.switchContiner .productContiner .productFive .productBox .seven {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 100px;
}

.switchContiner .productContiner .productFive .productBox .eight {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 80px;
}

.switchContiner .productContiner .productFive .productBox .nine {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 80px;
}

.switchContiner .productContiner .productFive .productBox .ten {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 100px;
}

.switchContiner .productContiner .productFive .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productFive .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productFive .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productFive .last {
    margin-right: 0;
}

/* F类产品 */
.switchContiner .productContiner .productSix {
    text-align: left;
    width: 1034px;
    margin-left: 183px;
    margin-top: 20px;
}

.switchContiner .productContiner .productSix .productBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 240px;
    height: 140px;
    background-color: #f5f5f5;
    margin-right: 24px;
    margin-bottom: 24px;
}

.switchContiner .productContiner .productSix .productBox .pic {
    position: absolute;
    top: 50px;
    left: 20px;
    width: 120px;
}

.switchContiner .productContiner .productSix .productBox .one {
    position: absolute;
    top: 18px;
    left: 20px;
    width: 110px;
}

.switchContiner .productContiner .productSix .productBox .two {
    position: absolute;
    top: 25px;
    left: 35px;
    width: 60px;
}

.switchContiner .productContiner .productSix .productBox:hover {
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
    transform: translate3d(0, -2px, 0);
    transition: all 0.2s linear;
}

.switchContiner .productContiner .productSix .productBox .next {
    position: absolute;
    top: 55px;
    right: 20px;
    width: 30px;
    height: 30px;
}

.switchContiner .productContiner .productSix .productBox .txt {
    position: absolute;
    left: 20px;
    bottom: 10px;
    font-size: 14px;
    transform: scale(0.8);
}

.switchContiner .productContiner .productSix .last {
    margin-right: 0;
}
</style>
